.app-header {
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    top: 0;
    height: 100px;
    justify-content: space-between;
    padding: 10px 15px;
    /* border-bottom: 1px solid; */
    text-transform: uppercase;
    letter-spacing: -.025em;
    align-items: center;
    background: linear-gradient(to bottom, rgb(250, 44, 44), #6f6de8,rgb(95, 170, 95),white);
    margin-bottom: 15px;
  color:white;
  /* color:purple,blue,skyblue,green,yellow,orange,red; */
  /* background: linear-gradient(to right,purple,blue,skyblue,green,yellow,orange,red); */
  }

  @media (min-width: 768px) {
    .app-header {
        padding: 10px 25px;
      }
};



  .login-controls, .logged-in-controls {
    flex: 40%;
    text-align: right;
  }
  
.header-link:first-child {

    padding: 7px;
    text-align: center;
    
  }

  .header-link:last-child {
    border: 1px solid black;
    padding: 7px;
    color:black;
    border-radius: 7px;
    text-align: center;
    width: fit-content;
    margin-right: 0px;
    margin-left: auto;
  }

  @media (min-width: 768px) {
    .header-link:last-of-type {
        margin-left: 15px;
    }
  }

  .login, .register, .header-user-name, .logout{
    display: block;

  }

  @media (min-width: 414px) {
    .login, .register, .header-user-name, .logout{
        display: inline;
        padding-left: 5px;
      }

  }
  @media (min-width: 768px) {
    .login, .register, .header-user-name, .logout{
        font-size: 1.25em;
      }

  }

  .header-logo {
    font-size: 1rem;
    flex: 20%;
    /* color:red; */
    letter-spacing: 1px;
  }
  
  @media (min-width: 768px) {
    .header-logo {
        font-size: 1.5rem;
    }
  }