@import url(https://fonts.googleapis.com/css?family=Ubuntu);
.app-header {
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    top: 0;
    height: 100px;
    justify-content: space-between;
    padding: 10px 15px;
    /* border-bottom: 1px solid; */
    text-transform: uppercase;
    letter-spacing: -.025em;
    align-items: center;
    background: -webkit-gradient(linear, left top, left bottom, from(rgb(250, 44, 44)), color-stop(#6f6de8),color-stop(rgb(95, 170, 95)),to(white));
    background: linear-gradient(to bottom, rgb(250, 44, 44), #6f6de8,rgb(95, 170, 95),white);
    margin-bottom: 15px;
  color:white;
  /* color:purple,blue,skyblue,green,yellow,orange,red; */
  /* background: linear-gradient(to right,purple,blue,skyblue,green,yellow,orange,red); */
  }

  @media (min-width: 768px) {
    .app-header {
        padding: 10px 25px;
      }
};



  .login-controls, .logged-in-controls {
    flex: 40% 1;
    text-align: right;
  }
  
.header-link:first-child {

    padding: 7px;
    text-align: center;
    
  }

  .header-link:last-child {
    border: 1px solid black;
    padding: 7px;
    color:black;
    border-radius: 7px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 0px;
    margin-left: auto;
  }

  @media (min-width: 768px) {
    .header-link:last-of-type {
        margin-left: 15px;
    }
  }

  .login, .register, .header-user-name, .logout{
    display: block;

  }

  @media (min-width: 414px) {
    .login, .register, .header-user-name, .logout{
        display: inline;
        padding-left: 5px;
      }

  }
  @media (min-width: 768px) {
    .login, .register, .header-user-name, .logout{
        font-size: 1.25em;
      }

  }

  .header-logo {
    font-size: 1rem;
    flex: 20% 1;
    /* color:red; */
    letter-spacing: 1px;
  }
  
  @media (min-width: 768px) {
    .header-logo {
        font-size: 1.5rem;
    }
  }



.form {
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    /* height: 50px; */
    /* background: blue; */
    /* overflow:hidden; */

}
@media (min-width: 414px) {
    .form {
        width: 60%;
    }

  }

.form-section {
    /* margin: 20px; */
    width: 100%;
    position: relative;
    height: 50px;
    /* background: blue; */
    /* overflow:hidden; */

}

.input {
    width: 100%;
    height: 100%;
    color: grey;
    padding-top: 10px;
    font-size: 20px;
    border: none;
    text-align: center;
    outline: none;

}
.label-name {
    position: absolute;
    bottom:0px;
    left:0%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 2px solid lightcoral;
}

.label-name::after {
    content: '';
    position: absolute;
    left:0px;
    bottom:-2px;
    height: 100%;
    width:100%;
    border-bottom: 4px solid skyblue;
    -webkit-transform: translateY(-3%);
            transform: translateY(-3%);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    
}

.content-name {
    position: absolute;
    bottom:5px;
    left:0px;
    right:0px;
    -webkit-transition:  all 0.3s ease;
    transition:  all 0.3s ease;
}

.input:focus + .label-name .content-name,
.input:valid + .label-name .content-name {
    -webkit-transform: translateY(-150%);
            transform: translateY(-150%);
    font-size:14px;
    color:skyblue;
}

.input:focus + .label-name::after,
.input:valid + .label-name::after {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);

}



.form-btn {
    width: 60%;
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    align-items: center;
}


.RegistrationForm__btn {
    margin: 20px;
    width: 100%;
    border: none;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: rgba(52, 52, 126, 0.726);
    padding: 10px;
    border-radius: 50px;
    display: inline-block;
    border: none;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    align-items: center;
    
}

.RegistrationForm__btn:hover {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
-webkit-transition: all 0.4s ease 0s;
transition: all 0.4s ease 0s;
    }


.RegistrationForm__footer{
    text-align: center;
    font-size: 1.5em;
}

.required {
    color: white;
}

@media (min-width: 414px) {
    .login, .register, .header-user-name, .logout{
        display: inline;
        padding-left: 5px;
      }

  }
.RegistrationRoute__body{
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
    /* background: grey; */
}

.RegistrationRoute__h3{
    max-width: 80%;
    padding: 10px;
    text-align: center;
    margin:0;
    border-radius: 7px;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
-webkit-transition: all 0.4s ease 0s;
transition: all 0.4s ease 0s;

}

.RegistrationRoute__image {
    width: 50%;
    border-radius: 5% 5% 50% 50%;
    margin: 10px;
    

}
.RegistrationRoute__h2{
    margin-bottom: 0px;
    text-transform: uppercase;
  
}
.LoginForm {
    margin: 20px;
    width: 100%;
    position: relative;
    /* height: 50px; */
    /* background: blue; */
    /* overflow:hidden; */

}
@media (min-width: 414px) {
    .LoginForm {
        width: 60%;
    }

  }

.form-section {
    /* margin: 20px; */
    width: 100%;
    position: relative;
    height: 50px;
    /* background: blue; */
    /* overflow:hidden; */

}

.input {
    width: 100%;
    height: 100%;
    color: grey;
    padding-top: 10px;
    font-size: 20px;
    border: none;
    text-align: center;
    outline: none;

}
.label-name {
    position: absolute;
    bottom:0px;
    left:0%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 2px solid lightcoral;
}

.label-name::after {
    content: '';
    position: absolute;
    left:0px;
    bottom:-2px;
    height: 100%;
    width:100%;
    border-bottom: 4px solid skyblue;
    -webkit-transform: translateY(-3%);
            transform: translateY(-3%);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    
}

.content-name {
    position: absolute;
    bottom:5px;
    left:0px;
    right:0px;
    -webkit-transition:  all 0.3s ease;
    transition:  all 0.3s ease;
}

.input:focus + .label-name .content-name,
.input:valid + .label-name .content-name {
    -webkit-transform: translateY(-150%);
            transform: translateY(-150%);
    font-size:14px;
    color:skyblue;
}

.input:focus + .label-name::after,
.input:valid + .label-name::after {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);

}



.form-btn {
    width: 60%;
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    align-items: center;
}


.LoginForm__btn {
    margin: 20px;
    width: 100%;
    border: none;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: rgba(52, 52, 126, 0.74);
    padding: 10px;
    border-radius: 50px;
    display: inline-block;
    border: none;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    align-items: center;
}

.LoginForm__btn:hover {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
-webkit-transition: all 0.4s ease 0s;
transition: all 0.4s ease 0s;
    }


.RegistrationForm__footer{
    text-align: center;
    font-size: 1.5em;
}

.required {
    color: white;
}

@media (min-width: 414px) {
    .login, .register, .header-user-name, .logout{
        display: inline;
        padding-left: 5px;
      }

  }
.LoginRoute__body{
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
    /* background: grey; */
}

.LoginRoute__h3{
    max-width: 80%;
    padding: 10px;
    text-align: center;
    border-radius:11px ;
    padding: 10px;
    /* background-color: rgba(173, 218, 129, 0.81); */
    color:linear-gradient(right, rgb(255, 67, 67),blue);
    /* color:rgb(0, 51, 255); */
    /* color: 0px 0px 6px rgba(255, 255, 255, 1); */
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 21, 255, 0.783);
    -moz-box-shadow: 0px 5px 40px -10px rgba(22, 47, 240, 0.666);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}

.LoginRoute__h2{
    text-transform: uppercase;
}
.dashboard{
    margin: auto;
    width: 95%;

  }

  @media screen and (min-width: 768px) {
      .dashboard{
        width: 75%;
      }
   
  }

  .score{
    text-align: center;
    font-size: 20px;
    padding:  10px 5px;
    text-transform: uppercase;

  }

  @media screen and (min-width: 768px) {
      .score {
        float: left;
        margin: 20px auto;
      }

  }

  .dashboard h2{
    text-align: center;
    margin: 15px auto;
    text-transform: uppercase;
    font-size: 30px;
    
  }

  /* .dashboard-header{
    background:repeating-linear-gradient(to right,red,white,blue);
    width: fit-content;
    padding: 5px;
    text-align: center;
  } */

  .bar{
    height: 100px;
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 0;

  }
  .bar button {
    text-align: center;
    background: -webkit-gradient(linear, right top, left top, from(green),to(rgb(128, 179, 128)) );
    background: linear-gradient(right, green,rgb(128, 179, 128) );
    color:white;
    padding:  10px 15px;
    border: 1px solid;
    text-transform: uppercase;
    margin: 10px auto;
    border-radius:11px ;


    
  }

  @media screen and (min-width: 768px) {
    .bar button {
        margin: 20px auto;
        float: right;
    }

}

  .bar button:hover {
        cursor: pointer;  
        border-radius:11px ;
        padding: 10px;
        /* background-color: rgba(173, 218, 129, 0.81); */
        color:linear-gradient(right, rgb(255, 67, 67),blue);
        /* color:rgb(0, 51, 255); */
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
        -webkit-box-shadow: 0px 5px 40px -10px rgba(2, 206, 9, 0.783);
        -moz-box-shadow: 0px 5px 40px -10px rgba(53, 187, 8, 0.666);
        -webkit-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
  }

  .dashboard h3{
    font-size: 24px;
    margin: 15px auto;
    text-align: center;
    text-transform: uppercase;
  }

  ul{
    text-align: center;
    margin: 15px auto;
    display: flex;
    flex-wrap: wrap;
    /* border: 2px solid black; */
    background: rgba(109, 178, 109, 0.584);
    border-radius: 7px;
    padding: 5px;
  }

  ul li{
      list-style: none;
      flex: 100% 1;
      margin: 2% 5%;
      line-height: 1.75rem;
      /* border: 1px solid black; */
      padding: 0px 0px 15px ;
    border-radius: 7px;
    background-color: rgba(135, 197, 0, 0.523);
}

@media screen and (min-width: 768px) {
    ul li {
        flex: 30% 1;
    }
  }


    .word-list-item h3{
        font-size: 20px;
        margin: 5px auto;
      }


    .word-list-item p{
        font-size: 16px;
      }
    .word-list-item h4{
        font-weight: bold;
        text-transform: uppercase;
      }


      .word-list-item h3{
        font-size: 20px;
        margin: 5px auto;

      }

      .word-list-item p{
        font-size: 16px;
      }
.LearningRoute{
    width: 90%;
    /* border: 1px black solid; */
    text-align: center;
    margin: auto;
    
}
    @media screen and (min-width: 768px) {
        .LearningRoute{
            width: 75%;
            }
    }

    .LearningRoute h2, h3{
        text-transform: uppercase;
      }
  

    .answers-count{
      width: 90%;
      margin: auto;
      }
      @media screen and (min-width: 768px) {
          .answers-count {
            display: flex;
            width: 83%;
          }
      }


      .count{
        /* border: 1px black solid; */
        margin: 15px auto;
        padding: 5px;
        background: -webkit-gradient(linear, right top, left top, from(rgba(135, 197, 0, 0.523)));
        background: linear-gradient(to left, rgba(135, 197, 0, 0.523));
        border-radius: 10px;
        padding: 2%;
        /* color: white; */
    }


    @media screen and (min-width: 768px) {
        .count{
            margin: 15px 2%;
            flex: 46% 1;
        }
    }




    .LearningRoute h2, h3{
      line-height: 30px;
      margin: 10px auto;
    }

    .LearningRoute h2{
      margin-top: 20px;
    }

    .DisplayScore h2{
        margin: 25px auto;
        width: 90%;
      }

      .DisplayScore p {
        width: 100%;
        /* background: lightgrey; */
        height: 35px;
        padding-top: 5px;
        margin: 0px;
        /* border-bottom: 1px solid black; */
        text-transform: uppercase;
        top: 0;
      }

    .DisplayFeedback{
      margin: 25px auto;
      width: 90%;
    }

      .DisplayFeedback p{
        margin: 25px auto;
      }
      @media screen and (min-width: 768px) {
        .DisplayFeedback p{
             width: 75%;
        }
    }



    .container{
      width: 90%;
      margin: auto;
    }

    @media screen and (min-width: 768px) {
        .container{
            width: 80%;
        }
      }

      .word-page-body{
        /* width: 100%; */
        /* height: 4rem; */
        /* border: 1px solid black; */
        /* display: flex; */
        /* justify-content: space-evenly; */
        /* flex-direction: column; */
        /* align-items: center; */
        background: rgba(109, 178, 109, 0.584);
        border-radius: 10px;
        padding: 5%;
        /* color: white; */
        /* border: #02084f solid 1px; */
        margin-top: 2rem;
      }

    #learn-guess-form{
      /* background: green; */
      padding: 20px 10px;
      margin: 15px auto;
      /* border: solid black 1px; */
      

      /* width: 100%; */
    /* height: 4rem; */
    /* border: 1px solid black; */
    /* display: flex; */
    /* justify-content: space-evenly; */
    /* flex-direction: column; */
    /* align-items: center; */
    background: rgba(135, 197, 0, 0.523);
    border-radius: 10px;
    padding: 7%;
    /* color: white; */
    /* border: #02084f solid 1px; */

    }

    #learn-guess-form input,label {
        display: block;
      }

      #learn-guess-form button {
        padding:  10px 15px;
        margin: 10px auto;
        border: none;
        background: rgba(109, 178, 109, 0.938);
        /* color: #ffffff !important; */
        font-weight: 100;
        padding: 20px;
        text-transform: uppercase;
        border-radius: 6px;
        display: inline-block;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
      }

        @media screen and (min-width: 768px) {
            #learn-guess-form button{
                width: 60%;
            }

        }

        #learn-guess-form button:hover {
          cursor: pointer;
          color: #faf8f8 !important;
          font-weight: 700 !important;
          letter-spacing: 3px;
          background: -webkit-gradient(linear, left top, left bottom, from(rgba(135, 197, 0, 0.523)),to(rgba(119, 211, 202, 0.673)) );
          background: linear-gradient(top, rgba(135, 197, 0, 0.523),rgba(119, 211, 202, 0.673) );
          -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
          -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
          -webkit-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }


        #learn-guess-form input{
        width: 90%;
        margin: 15px auto;
        }

        @media screen and (min-width: 768px) {
            #learn-guess-form input{
                width: 60%;
            } 
        }

     

.nextWord__btn {
    border-radius: 7px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(135, 197, 0, 0.523)), to(rgba(77, 174, 145, 0.557))) !important;
    background: linear-gradient(to right, rgba(135, 197, 0, 0.523), rgba(77, 174, 145, 0.557)) !important;
    border: none;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    padding: 15px 27px 15px 0px;
    /* width: 200px; */
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    cursor: pointer;
    margin: 5px;
  }
  .nextWord__btn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .nextWord__btn span:after {
    content: '\00bb\00bb\00bb';
    /* position: absolute; */
    opacity: 0;
    top: 0;
    right: -20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .nextWord__btn:hover span {
    padding-right: 25px;
  }
  .nextWord__btn:hover span:after {
    opacity: 1;
    right: 0;
  }
.green{
    color: green;
  }
  
  .red{
    color: red;
  }
  
  .bold{
    font-weight: bold;
  }
.alert{
    color:red;
    margin: 10px auto;
}


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size: calc(10px + 1vmin);
}

a {
  color: inherit;
  text-decoration: none;

}

