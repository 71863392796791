.green{
    color: green;
  }
  
  .red{
    color: red;
  }
  
  .bold{
    font-weight: bold;
  }
.alert{
    color:red;
    margin: 10px auto;
}

