.dashboard{
    margin: auto;
    width: 95%;

  }

  @media screen and (min-width: 768px) {
      .dashboard{
        width: 75%;
      }
   
  }

  .score{
    text-align: center;
    font-size: 20px;
    padding:  10px 5px;
    text-transform: uppercase;

  }

  @media screen and (min-width: 768px) {
      .score {
        float: left;
        margin: 20px auto;
      }

  }

  .dashboard h2{
    text-align: center;
    margin: 15px auto;
    text-transform: uppercase;
    font-size: 30px;
    
  }

  /* .dashboard-header{
    background:repeating-linear-gradient(to right,red,white,blue);
    width: fit-content;
    padding: 5px;
    text-align: center;
  } */

  .bar{
    height: 100px;
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 0;

  }
  .bar button {
    text-align: center;
    background: linear-gradient(right, green,rgb(128, 179, 128) );
    color:white;
    padding:  10px 15px;
    border: 1px solid;
    text-transform: uppercase;
    margin: 10px auto;
    border-radius:11px ;


    
  }

  @media screen and (min-width: 768px) {
    .bar button {
        margin: 20px auto;
        float: right;
    }

}

  .bar button:hover {
        cursor: pointer;  
        border-radius:11px ;
        padding: 10px;
        /* background-color: rgba(173, 218, 129, 0.81); */
        color:linear-gradient(right, rgb(255, 67, 67),blue);
        /* color:rgb(0, 51, 255); */
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
        -webkit-box-shadow: 0px 5px 40px -10px rgba(2, 206, 9, 0.783);
        -moz-box-shadow: 0px 5px 40px -10px rgba(53, 187, 8, 0.666);
        transition: all 0.4s ease 0s;
  }

  .dashboard h3{
    font-size: 24px;
    margin: 15px auto;
    text-align: center;
    text-transform: uppercase;
  }

  ul{
    text-align: center;
    margin: 15px auto;
    display: flex;
    flex-wrap: wrap;
    /* border: 2px solid black; */
    background: rgba(109, 178, 109, 0.584);
    border-radius: 7px;
    padding: 5px;
  }

  ul li{
      list-style: none;
      flex: 100%;
      margin: 2% 5%;
      line-height: 1.75rem;
      /* border: 1px solid black; */
      padding: 0px 0px 15px ;
    border-radius: 7px;
    background-color: rgba(135, 197, 0, 0.523);
}

@media screen and (min-width: 768px) {
    ul li {
        flex: 30%;
    }
  }


    .word-list-item h3{
        font-size: 20px;
        margin: 5px auto;
      }


    .word-list-item p{
        font-size: 16px;
      }
    .word-list-item h4{
        font-weight: bold;
        text-transform: uppercase;
      }


      .word-list-item h3{
        font-size: 20px;
        margin: 5px auto;

      }

      .word-list-item p{
        font-size: 16px;
      }